/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

h1 {
	@apply text-4xl font-extrabold tracking-tight leading-none text-accent;
}

h2 {
	@apply text-primary-700;
	@apply font-semibold;
	@apply text-2xl;
	@apply border-b-2;
}



.NgxEditor__Wrapper {
	margin-top: 0.5rem;
	z-index: 5;

	.NgxEditor__HelpText.NgxEditor__HelpText--Error {
		display: none;
	}
	
	input {
		@apply border border-gray-300 p-1 rounded-md;

		&.ng-invalid.ng-touched {
			@apply border-warn;
		}
	}

	button {
		@apply bg-primary rounded-md px-3 py-1 text-on-primary;
	}


	@apply border border-gray-300 #{'!important'};
	.NgxEditor {
		height: 7rem;
		min-height: 7rem;
		overflow-y: auto;

		.NgxEditor__Content {
		
			height: 7rem;
			min-height: 7rem;
			font-family: inherit;
		}
	}

}
